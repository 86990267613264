<template>
    <div class="wrapper-landing">
      <LandingHero
        title="La lista di tutto ciò di cui il tuo bambino avrà bisogno"
        subtitle="Salva ciò che desideri per il tuo bambino per organizzarti e condividerlo con le persone che ami di più."
        origin="listabebe"
        lang="it-IT"
        buttonText="Inizia la tua lista ora"
      >
        <template v-slot:cover>
          <source
            media="(max-width: 991px)"
            class=""
            srcset="../../assets/img/landings/cover-mujer-joven-embarazada-movil-resp.jpg"
            alt=""
            loading="lazy"
          />
          <img
            class="ln-hero__inset"
            src="../../assets/img/landings/cover-mujer-joven-embarazada-movil.jpg"
            alt=""
            loading="lazy"
          />
        </template>
      </LandingHero>
      <main class="bg-cercles">
        <LandingAppRatings />
  
        <section class="bb-container">
          <LandingFeaturesSimple title="Hai bisogno di una lista HelloBB se:"
          :use-carousel="false"
          >
            <LandingFeaturesSimpleItem>
              <template slot="ico">
                <img src="../../assets/img/ico/ico-color-embarazada.svg" alt="" />
              </template>
              <template slot="title">
                Sei un neogenitore e non sai di cosa hai davvero bisogno.
              </template>
            </LandingFeaturesSimpleItem>
            <LandingFeaturesSimpleItem>
              <template slot="ico">
                <img src="../../assets/img/ico/ico-color-q.svg" alt="" />
              </template>
              <template slot="title">
                Familiari e amici continuano a chiederti cosa desideri per il piccolo.
              </template>
            </LandingFeaturesSimpleItem>
            <LandingFeaturesSimpleItem>
              <template slot="ico">
                <img src="../../assets/img/ico/ico-color-mobile-cart.svg" alt="" />
              </template>
              <template slot="title"> Vuoi scegliere articoli da negozi diversi. </template>
            </LandingFeaturesSimpleItem>
            <LandingFeaturesSimpleItem>
              <template slot="ico">
                <img src="../../assets/img/ico/ico-color-hand-heart.svg" alt="" />
              </template>
              <template slot="title">
                Cerchi un modo semplice e carino per condividere i tuoi desideri.
              </template>
            </LandingFeaturesSimpleItem>
            <LandingFeaturesSimpleItem>
              <template slot="ico">
                <img src="../../assets/img/ico/ico-color-clipboard.svg" alt="" />
              </template>
              <template slot="title">
                Ti piace organizzarti e non vuoi dimenticare nulla.
              </template>
            </LandingFeaturesSimpleItem>
          </LandingFeaturesSimple>
        </section>
      </main>
  
      <LandingFeaturesCover title="Una lista piena di cose belle">
        <LandingFeaturesCoverItem>
          <template slot="cover">
            <img src="../../assets/img/landings/cover-retailers-box-it.png" alt="" />
          </template>
          <template slot="titulo">
            Richiedi qualsiasi modello, di qualsiasi marca, da qualsiasi negozio.
          </template>
  
          <template slot="descripcion">
            <p>
                Copia un link e aggiungilo alla tua lista. Libertà totale per scegliere esattamente ciò che desideri.
            </p>
          </template>
        </LandingFeaturesCoverItem>
        <!-- <LandingFeaturesCoverItem>
          <template slot="cover">
            <img src="../../assets/img/covers/cover-cashback.svg" alt="" />
          </template>
          <template slot="titulo"> Gana un 5% de lo que gastéis </template>
  
          <template slot="descripcion">
            <p>
              Obtendrás un cheque regalo por valor de un 5% de todo lo que tú, tus
              familiares y tus amigos compréis en la tienda de HelloBB (si llegáis a los
              500€).
            </p>
          </template>
        </LandingFeaturesCoverItem> -->
        <LandingFeaturesCoverItem>
          <template slot="cover">
            <img src="../../assets/img/covers/cover-regalos-efectivo.svg" alt="" />
          </template>
          <template slot="titulo"> Ricevi regali o denaro </template>
  
          <template slot="descripcion">
            <p>
                La tua famiglia e i tuoi amici potranno regalarti articoli o inviarti denaro per acquistare ciò che preferisci.
            </p>
          </template>
        </LandingFeaturesCoverItem>
        <LandingFeaturesCoverItem>
          <template slot="cover">
            <img src="../../assets/img/covers/mockup-aportacions-it.png" alt="" />
          </template>
          <template slot="titulo"> Possibilità di dividere le spese dei prodotti più costosi </template>
  
          <template slot="descripcion">
            <p>
                La tua famiglia e i tuoi amici potranno contribuire all'acquisto dei prodotti più costosi.
            </p>
          </template>
        </LandingFeaturesCoverItem>
      </LandingFeaturesCover>
  
      <main class="bg-cercles">
        <section class="bb-container">
          <LandingReviews lang="it-IT" />
          <!-- <LandingHablan /> -->
        </section>
      </main>
  
      <LandingComoCols title="Come funziona?" subtitle="La tua lista nascita digitale">
        <LandingComoColsItem>
          <template slot="icono">
            <img src="../../assets/img/ico/ico-max-click.svg" alt="" />
          </template>
          <template slot="titulo">Scegli ciò che desideri</template>
          <template slot="descripcion">
            <p>
                Seleziona gli articoli che vuoi da qualsiasi negozio online, copia il link su HelloBB e salvalo.
            </p>
            <p>
                Hai bisogno di idee o non sai cosa comprare? Nessun problema, ti suggeriremo i migliori prodotti.
            </p>
            <p>
                Hai bisogno di idee o non sai cosa comprare? Nessun problema, ti suggeriremo i migliori prodotti.
            </p>
          </template>
        </LandingComoColsItem>
        <LandingComoColsItem>
          <template slot="icono">
            <img src="../../assets/img/ico/ico-max-share.svg" alt="" />
          </template>
          <template slot="titulo">Condividi i tuoi desideri</template>
          <template slot="descripcion">
            <p>Quando sei pronto, condividi la tua lista con familiari e amici.</p>
            <p>
                Invia loro un link per accedere a una lista personalizzata, da qualsiasi dispositivo e senza bisogno di registrazione.
            </p>
          </template>
        </LandingComoColsItem>
        <LandingComoColsItem>
          <template slot="icono">
            <img src="../../assets/img/ico/ico-max-gift.svg" alt="" />
          </template>
          <template slot="titulo">Ricevi i tuoi regali</template>
          <template slot="descripcion">
            <p>
                I tuoi cari potranno scegliere il regalo che preferiscono dalla tua lista e acquistarlo. Potranno inviartelo direttamente o tenerlo per offrirlo durante una visita.
            </p>
            <p>
                Per evitare doppioni, potranno segnare che l'articolo è già stato regalato.
            </p>
          </template>
        </LandingComoColsItem>
      </LandingComoCols>
  
      <LandingFooter lang="it-IT" />
    </div>
  </template>
  
  <script>
  import LandingFeaturesSlider from "./components/LandingFeaturesSlider.vue";
  
  import LandingFeaturesSimple from "./components/LandingFeaturesSimple.vue";
  import LandingFeaturesSimpleItem from "./components/LandingFeaturesSimpleItem.vue";
  
  import LandingHero from "./components/LandingHero.vue";
  import LandingAppRatings from "./components/LandingAppRatings.vue";
  import LandingFeaturesCover from "./components/LandingFeaturesCover";
  import LandingFeaturesCoverItem from "./components/LandingFeaturesCoverItem";
  import LandingReviews from "./components/LandingReviews.vue";
  import LandingHablan from "./components/LandingHablan.vue";
  import LandingFooter from "./components/LandingFooter.vue";
  
  import LandingComoCards from "./components/LandingComoCards.vue";
  import LandingComoCardsItem from "./components/LandingComoCardsItem.vue";
  import LandingComoColsItem from "./components/LandingComoColsItem.vue";
  import LandingComoCols from "./components/LandingComoCols.vue";
  
  // import bbButton from "../../components/buttons/bbButton.vue";
  
  export default {
    name: "LandingNoListIT",
    metaInfo() {
      return {
        title: "La lista del tuo bambino | HelloBB",
        meta: [
          { name: "description", content: "Crea la lista del tuo bambino con HelloBB" },
          { name: "robots", content: "noindex" },
        ],
      };
    },
    components: {
      LandingComoCols,
      LandingComoColsItem,
      LandingAppRatings,
      LandingHablan,
      LandingReviews,
      LandingFooter,
      LandingHero,
      LandingFeaturesCover,
      LandingFeaturesCoverItem,
      LandingFeaturesSlider,
      LandingFeaturesSimple,
      LandingFeaturesSimpleItem,
      LandingComoCards,
      LandingComoCardsItem,
    },
  };
  </script>
  
  <style lang="scss" scoped></style>
  